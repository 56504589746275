// import { Image } from "./image";
import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const Galery = (props) => {
  const [index, setIndex] = useState(-1);

  const images = [
    {
      src: "img/galeri/1.jpg",
      original: "img/galeri/1.jpg",
      width: 240,
      height: 320,
    },
    {
      src: "img/galeri/2.jpg",
      original: "img/galeri/2.jpg",
      width: 400,
      height: 300,
    },
    {
      src: "img/galeri/3.jpg",
      original: "img/galeri/3.jpg",
      width: 370,
      height: 240,
    },
    {
      src: "img/galeri/4.jpg",
      original: "img/galeri/4.jpg",
      width: 300,
      height: 180,
    },
    {
      src: "img/galeri/6.jpg",
      original: "img/galeri/6.jpg",
      width: 240,
      height: 183,
    },

    {
      src: "img/galeri/28.jpg",
      original: "img/galeri/28.jpg",
      width: 240,
      height: 320,
    },
    {
      src: "img/galeri/10.jpg",
      original: "img/galeri/10.jpg",
      width: 270,
      height: 190,
    },
    {
      src: "img/galeri/24.png",
      original: "img/galeri/24.png",
      width: 260,
      height: 148,
      // tags: [{ value: "People", title: "People" }],
    },
    {
      src: "img/galeri/8.jpg",
      original: "img/galeri/8.jpg",
      width: 320,
      height: 200,
    },
    {
      src: "img/galeri/30.jpg",
      original: "img/galeri/30.jpg",
      width: 248,
      height: 320,
    },

    // {
    //   src: "img/galeri/26.jpg",
    //   original: "img/galeri/26.jpg",
    //   width: 320,
    //   height: 173,
    // },
    // {
    //   src: "img/galeri/13.jpg",
    //   original: "img/galeri/13.jpg",
    //   width: 313,
    //   height: 320,
    // },
    // {
    //   src: "img/galeri/9.jpg",
    //   original: "img/galeri/9.jpg",
    //   width: 320,
    //   height: 213,
    // },
    // {
    //   src: "img/galeri/25.jpg",
    //   original: "img/galeri/25.jpg",
    //   width: 270,
    //   height: 194,
    // },
    // {
    //   src: "img/galeri/16.jpg",
    //   original: "img/galeri/16.jpg",
    //   width: 300,
    //   height: 213,
    // },

    // {
    //   src: "img/galeri/13.jpg",
    //   original: "img/galeri/13.jpg",
    //   width: 271,
    //   height: 320,
    // },
    // {
    //   src: "img/galeri/17.jpg",
    //   original: "img/galeri/17.jpg",
    //   width: 320,
    //   height: 213,
    // },
    // {
    //   src: "img/galeri/5.jpg",
    //   original: "img/galeri/5.jpg",
    //   width: 320,
    //   height: 213,
    // },
    // {
    //   src: "img/galeri/15.jpg",
    //   original: "img/galeri/15.jpg",
    //   width: 320,
    //   height: 213,
    // },
    // {
    //   src: "img/galeri/23.jpg",
    //   original: "img/galeri/23.jpg",
    //   width: 320,
    //   height: 213,
    // },

    // {
    //   src: "img/galeri/20.jpg",
    //   original: "img/galeri/20.jpg",
    //   width: 220,
    //   height: 160,
    // },
    // {
    //   src: "img/galeri/12.jpg",
    //   original: "img/galeri/12.jpg",
    //   width: 220,
    //   height: 160,
    // },
    // {
    //   src: "img/galeri/7.jpg",
    //   original: "img/galeri/7.jpg",
    //   width: 220,
    //   height: 160,
    // },
    // {
    //   src: "img/galeri/14.jpg",
    //   original: "img/galeri/14.jpg",
    //   width: 220,
    //   height: 160,
    // },
    // {
    //   src: "img/galeri/18.jpg",
    //   original: "img/galeri/18.jpg",
    //   width: 220,
    //   height: 160,
    // },

    // {
    //   src: "img/galeri/27.jpg",
    //   original: "img/galeri/27.jpg",
    //   width: 320,
    //   height: 173,
    // },
    // {
    //   src: "img/galeri/29.jpg",
    //   original: "img/galeri/29.jpg",
    //   width: 300,
    //   height: 200,
    // },
    // {
    //   src: "img/galeri/11.jpg",
    //   original: "img/galeri/11.jpg",
    //   width: 320,
    //   height: 213,
    // },
    // {
    //   src: "img/galeri/22.jpg",
    //   original: "img/galeri/22.jpg",
    //   width: 270,
    //   height: 194,
    // },
    // {
    //   src: "img/galeri/19.jpg",
    //   original: "img/galeri/19.jpg",
    //   width: 300,
    //   height: 213,
    // },
  ];
  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galeri</h2>
        </div>
        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </div>
  );
};
