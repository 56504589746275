import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          {/* <h2>LAYANAN</h2> */}
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className="fa fa-comments-o"></i>
            <p>
              Memberikan pelayanan yang ramah pada setiap klien yang
              mempercayakan pengamanan kepada kami
            </p>
          </div>
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className="fa fa-comments-o"></i>
            <p>
              Memiliki banyak pengalaman bekerja dengan klien dalam memecahkan
              masalah penanganan keamanan dan pengelolaan SDM
            </p>
          </div>
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className="fa fa-comments-o"></i>
            <p>
              Kami memiliki kompetensi dan keahlian yang kuat di bidang jasa
              keamanan, sehingga kami mampu memberikan prioritas utama pada
              aspek keamanan bagi klien kami.
            </p>
          </div>
          <div className="col-xs-6 col-md-3">
            {" "}
            <i className="fa fa-comments-o"></i>
            <p>
              Merupakan perusahaan jasa keamanan yang ada di Kota Semarang dan
              berkonsentrasi penuh pada keamanan
            </p>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-xs-6 col-md-12">
            {" "}
            <i className="fa fa-comments-o"></i>
            <p>
              Kami memiliki tim kerja yang terlatih dan handal di bidang
              keamanan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
