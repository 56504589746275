import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/LOGOLUJ.png"
              style={{ width: "60%" }}
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Tentang Kami</h2>
              <p>
                Kami adalah perusahaan keamanan terdepan yang menyediakan jasa
                keamanan satpam berkualitas tinggi untuk berbagai jenis
                properti, mulai dari rumah, kantor, ruko, pabrik, hingga
                tempat-tempat lain yang membutuhkan layanan keamanan terbaik.
              </p>
              <p>
                Kami memahami betapa pentingnya merasa aman dan nyaman dalam
                beraktifitas, dan itulah mengapa PT LIMA UTAMA JAYA hadir untuk
                memberikan solusi keamanan terbaik untuk pengguna jasa kami.
                Kami didukung oleh sistem manajemen keamanan yang handal dan
                patroli pembinaan anggota satpam secara berkala untuk memastikan
                bahwa standar operasional prosedur (SOP) kami selalu terjaga
                dengan baik.
              </p>
              <p>
                Kami bangga memiliki tim satpam yang terlatih dan berpengalaman
                serta didukung oleh teknologi keamanan terkini untuk memberikan
                perlindungan terbaik bagi pelanggan kami. Komitmen kami adalah
                untuk selalu mengutamakan kualitas, transparansi, dan
                profesionalisme dalam setiap layanan yang kami berikan.
              </p>
              <h3>Mengapa memilih kami?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>
                      Klien tidak perlu merekrut tenaga kerja keamanan secara
                      langsung.
                    </li>
                    <li>
                      Klien akan mendapatkan tenaga kerja dengan standar yang
                      diharapkan.
                    </li>
                    <li>
                      Klien tidak perlu memberikan pengarahan kepada tenaga
                      kerja tentang sikap mental dan ketenagakerjaan yang tidak
                      teknis.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>
                      Klien berhak menerapkan semua peraturan perusahaan kepada
                      tenaga kerja kami.
                    </li>
                    <li>
                      Klien tidak perlu repot dengan masalah administratif
                      ketenagakerjaan.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
