import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Informasi Kontak</h2>
                <img
                  className="img-responsive"
                  src="img/LOGOLUJ.png"
                  width={200}
                  style={{ margin: "0 auto" }}
                />
                <br />
                <div
                  className="text-center"
                  style={{ fontSize: "1.6rem", fontWeight: "800" }}
                >
                  Perusahaan Penyedia Jasa Keamanan (Outsourcing Security
                  Provider)
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-3 col-md-offset-1 contact-info"
            style={{ fontWeight: "bold" }}
          >
            <div className="contact-item">
              <br />
              <br />
              <br />
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Alamat
                </span>
                Jl. Waru Timur Raya No.6, Pedalangan, Kec. Banyumanik, Kota
                Semarang, Jawa Tengah 50268
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> No Telepon
                </span>{" "}
                0819-1463-1094
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                ptlimautamajaya@gmail.com
              </p>
              <p>
                <span>
                  <i className="fa fa-instagram"></i> Instagram
                </span>{" "}
                @ptlimautamajaya
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/ptlimautamajaya/">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; {new Date().getFullYear()} PT. Lima Utama Jaya</p>
          {/* Icon{" "}
          <a href="http://www.freepik.com">Designed by macrovector / Freepik</a> */}
        </div>
      </div>
    </div>
  );
};
