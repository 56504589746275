import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

export const Partner = (props) => {
  return (
    <div id="partner" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Partner</h2>
          <p>
            Sebagai perusahaan jasa keamanan yang unggul, kami telah menjalin
            kerja sama dengan perusahaan terkemuka di Kota Semarang dan wilayah
            Jawa Tengah. Dengan kompetensi yang kami miliki di bidang keamanan,
            kami siap memberikan layanan yang lebih aman dan terpercaya dari
            yang lain.
          </p>
        </div>
        <div id="row" style={{ height: "30vh" }}>
          <Swiper
            spaceBetween={20}
            slidesPerView={2}
            loop={true}
            rewind={true}
            // centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              {/* <div style={{ backgroundColor: "#c7c7c7" }}> */}
              <img src="img/logo-bsb.png" />
              {/* </div> */}
              <div className="section-title">
                <p>PT KARYADEKA ALAM LESTARI</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {/* <div style={{ backgroundColor: "#c7c7c7" }}> */}
              <img src="img/logo-indoasia.jpg" width={150} />
              {/* </div>  */}
            </SwiperSlide>
            <SwiperSlide>
              {/* <div style={{ backgroundColor: "#c7c7c7" }}> */}
              <img src="img/rsud-majalengka.png" width={150} />
              {/* </div> */}
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="https://www.tatamulia.co.id/contents/logo/87tfqa20220131154116.png"
                width={150}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img src="img/surya-marga.png" width={250} />
              <div className="section-title">
                <p>PT SURYA MARGA LUHUR</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};
